import './App.scss';
import { CacheLocation, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import useConstellar from 'hooks/useConstellar';
import Home from 'pages/Home';

const scriptsData = `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//www.blenderanalytics.com/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '13']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const ggTagScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-JXQCGWR6J7');
`;

const App: React.FC = () => {
  useConstellar();

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = scriptsData;
    script.async = true;
    document.body.appendChild(script);

    const scriptGG = document.createElement('script');
    scriptGG.innerHTML = ggTagScript;
    document.body.appendChild(scriptGG);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<div className="notfound">Not Found!</div>} />
    </Routes>
  );
};

const AppProvider: React.FC = () => (
  <FpjsProvider
    loadOptions={{
      apiKey: 'WGvxQ7BCdZ3OTlpwsw7J',
      region: 'ap',
      scriptUrlPattern: '/loader.js',
    }}
    cacheLocation={CacheLocation.LocalStorage}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FpjsProvider>
);

export default AppProvider;
